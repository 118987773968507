const dayjs = require("dayjs");

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const now = () => {
  return dayjs().toISOString();
};

const formattedDate = (date) => {
  return dayjs(date).format("DD MMM YYYY");
};

const formattedDateTime = (date) => {
  return dayjs(date).format("DD MMM YYYY hh:mm:ss A");
};

const calendarDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
}

const isPast = (time) => {
  const date = dayjs(time);
  return date.isBefore(dayjs());
};

export { now, formattedDate, formattedDateTime, isPast, calendarDate};
