import * as React from "react";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default function DescriptionAlerts(props) {
  return (
    <Box sx={{ ...props?.containerStyle }}>
      <Alert severity={props.severity || "info"}>
        <AlertTitle>
          {props.message == null ? "No Data Available" : props.message}
        </AlertTitle>
      </Alert>
    </Box>
  );
}
