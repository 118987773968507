import DashboardHeader from "../componets/home/DashboardHeader";
import NavBar from "../componets/home/NavBar";
import Routes from "../routes/Routes";

import { Box } from "@mui/material";
import { Grid } from "@mui/material";

function Home() {
  console.log("Loading Home")
  return (
    <Box>
      <DashboardHeader />
      <Grid container>
        <Grid xs={2}>
          <NavBar />
        </Grid>
        <Grid xs={10}>
          <Routes></Routes>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
