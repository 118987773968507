import React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { formattedDate } from "../../utils/time";
import { TableHead } from "@material-ui/core";

const EventSummary = ({ event }) => {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow key="name">
              <TableCell variant="title">Event Name</TableCell>
              <TableCell variant="title">Description</TableCell>
              <TableCell variant="title">Fee</TableCell>
              <TableCell variant="title">Start Date</TableCell>
              <TableCell variant="title">End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="startDate">
              <TableCell variant="value">{event.title}</TableCell>
              <TableCell variant="value" width="40%">
                {event.description}
              </TableCell>
              <TableCell variant="value">{event.fees}</TableCell>
              <TableCell variant="value">
                {formattedDate(event.startDate)}
              </TableCell>
              <TableCell variant="value">
                {formattedDate(event.endDate)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EventSummary;
