import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function PaginationUI(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        width: "fit-content",
        marginTop: 5,
        marginBottom: 2,
      }}
    >
      <Stack spacing={2}>
        <Pagination
          count={props.totalPages}
          page={props.page}
          variant="outlined"
          shape="rounded"
          onChange={props.handleChange}
        />
      </Stack>
    </Box>
  );
}
