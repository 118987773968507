import React from "react";
import Grid from "@mui/material/Grid";

import MediaCard from "./MediaCard";
import DescriptionAlerts from "../ui/dialogs/DescriptionAlerts";

const MediaList = ({ media, onSelect, selected }) => {
  return (media?.length > 0) ? (
    <Grid container spacing={1}>
      {media?.map((item) => (
        <MediaCard
          item={item}
          key={item.id}
          onClick={() => onSelect(item)}
          highlight={selected?.find((i) => i.id === item.id)}
        />
      ))}
    </Grid>
  ) : (
    <DescriptionAlerts message="No Media Yet" />
  );
};

export default MediaList
