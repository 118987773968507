const basic = {
  name: "default",
  title: "Default Theme",
  styles: {
    lightText: "#C5C6C7",
    lightestText: "#66FCF1",
    mediumText: "#45A29E",
    accent: "white",
    toolbar: "#45A29E",
    background: "#1F2833",
    button: "#66FCF1",
    buttonText: "#1F2833",
    sidebar: "#0B0C10",
    border: "#707070",
    backdrop: "#000000D9",
    title: "#470d03", //Used in Botttom Sheet
    seperator: "#c4c4c4", //Used in Bottom Sheet
    red: "#FF2323",
    green: "#01D20F",
  },
};

export default basic;
