import React from "react";

import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import "../../css/Dashboard.css";
import "./NavBar.css";
import Colors from "../../constants/colors";

const NavBar = () => {
  const navBarList = [
    { label: "Events", link: "/events" },
    { label: "Users", link: "/users" },
    { label: "MarketPlace", link: "/marketPlace" },
    { label: "Theme", link: "/theme" },
    { label: "Transactions", link: "/order" },
    { label: "Reports", link: "/reports" },
  ];

  return (
    <Box
      role="presentation"
      sx={{
        backgroundColor: Colors.backgroundColor,
        minHeight: `calc(100vh - 64px)`,
        height: "100%",
      }}
    >
      <List sx={{ paddingTop: 0 }}>
        {navBarList.map((item, index) => (
          <NavLink
            to={item.link}
            className={(isActive) => (isActive ? "active" : "")}
          >
            {item.label}
          </NavLink>
        ))}
      </List>
      <Divider />
    </Box>
  );
};

export default NavBar;
