import React, { useState, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import Box from "@mui/material/Box";
import { userDetailsSchema } from "../utils/validate";

import EditUserForm from "./users/EditUserForm";

const UserDetailsForm = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imgData, setImgData] = useState(null);

  const methods = useForm({
    resolver: yupResolver(userDetailsSchema),
    defaultValues: props?.user,
  });

  useEffect(() => {
    setImgData(props.user?.profile);
  }, [props.user?.profile]);

  const handleUpdate = async (data) => {
    const dirtyFields = methods.formState.dirtyFields;
    let updatedFields = {};
    for (let key in dirtyFields) {
      if (dirtyFields[key] === true) {
        updatedFields[key] = data[key];
      }
    }
    if (imageFile) {
      updatedFields["photo"] = imageFile;
    }
    if (Object.keys(updatedFields).length === 0) {
      return;
    }
    props?.onSubmit(updatedFields)
  };

  const fileHandler = (e) => {
    if (e.length > 0) {
      setImageFile(e[0]);
      setImgData(URL.createObjectURL(e[0]));
    }
  };

  const discardHandler = () => {
    //For some reason reset doesn't work well will mui TextField
    for (let key in props?.user) {
      methods.setValue(key, props?.user[key])
    }
  }

  return (
    <Box sx={{ marginLeft: 3 }}>
      <FormProvider {...methods}>
        <EditUserForm
          onSubmit={methods.handleSubmit(handleUpdate)}
          onDiscard={discardHandler}
          item={props.user}
          filerHandler={fileHandler}
          img={imgData}
        />
      </FormProvider>
    </Box>
  );
}

export default UserDetailsForm;
