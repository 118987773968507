import React, { useState, useEffect, useContext } from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import ProgressIndicator from "../ui/ProgressIndicator";

import { formattedDate } from "../../utils/time";

const EventTable = (props) => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const { getEvents } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["events", page + 1, sort],
    () => getEvents(page + 1, sort),
    {
      keepPreviousData: true,
    }
  );

  const columns = [
    { field: "title", headerName: "Event title", flex: 1 },
    {
      field: "createdAt",
      headerName: "Creation Date",
      flex: 1,
      valueGetter: (params) => {
        const date = params?.row?.createdAt;
        return formattedDate(date);
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      valueGetter: (params) => {
        const date = params?.row?.endDate;
        return formattedDate(date);
      },
    },
    {
      field: "numberOfEntries",
      headerName: "Number of entries",
      flex: 1,
      valueGetter: (params) => params.row?.submissions?.length,
    },
    { field: "fees", headerName: "Fees", flex: 1 },
    {
      field: "pendingActions",
      headerName: "Pending actions",
      flex: 1,
      valueGetter: (params) => {
        const pending = params?.row?.submissions?.filter(
          (m) => m?.status === "pending"
        );
        return pending?.length || 0;
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      flex: 1,
      valueGetter: (params) => {
        const pending = params?.row?.submissions?.filter(
          (m) => m?.status === "approved"
        );

        return pending?.length || 0;
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 1,
      valueGetter: (params) => {
        const pending = params?.row?.submissions?.filter(
          (m) => m?.status === "rejected"
        );

        return pending?.length || 0;
      },
    },
  ];

  const pageChangeHandler = (page) => {
    setPage(page);
  };

  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel?.length === 0) {
      setSort("");
    } else {
      let sortBy =
        sortModel[0]?.sort === "asc"
          ? `-${sortModel[0]?.field}`
          : sortModel[0]?.field;
      setSort(sortBy);
    }
    setPage(0);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={data?.data?.results || []}
        columns={columns}
        rowCount={data?.data?.totalResults || 0}
        loading={isLoading}
        pagination
        paginationMode="server"
        pageSize={10}
        page={page}
        rowsPerPageOptions={[10]}
        autoPageSize={false}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onPageChange={pageChangeHandler}
        onRowClick={(data) => props?.onSelect(data.row)}
      ></DataGrid>

      <ProgressIndicator open={isLoading} />
    </Box>
  );
};

export default EventTable;
