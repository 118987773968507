import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import { useMutation, useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import MediaList from "../../componets/events/MediaList";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import EventSummary from "../../componets/events/EventSummary";
import PaginationUI from "../../componets/ui/PaginationUI";
import { MediaSubmissionStatusSelector } from "../../componets/events/CheckBoxGroup";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";
import Winners from "../../componets/ui/Winners";
import SubmissionTable from "./SubmissionTable";
import useWinnerSelector from "../../hooks/useWinnerSelector";

import { isPast } from "../../utils/time";

const EventDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [mode, setMode] = useState(false); //This is for selecting winners
  const [winners, winnerSelect, winnerReset] = useWinnerSelector();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();
  const [gridView, setGridView] = useState(true);

  const event = location?.state;

  const { getEventSubmissions, submitEventWinners } = useContext(APIContext);

  const { data, isLoading, isFetching } = useQuery(
    ["eventMedia", event.id, page, filter],
    () => getEventSubmissions(event.id, page, filter),
    {
      enabled: !!event.id,
    }
  );

  const submitWinnersMutation = useMutation(
    ({ eventId, winners }) => submitEventWinners(eventId, winners),
    {
      onSuccess: (data) => {
        //queryClient.invalidateQueries(["events"]);
        //history.goBack();
      },
      onError: (error) => {
        console.log(error);
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error Connecting to Server. Try again."
        );
        setShowError(true);
      },
    }
  );

  useEffect(() => {
    if (event) {
      for (let i = 0; i < event.winners?.length; i++) {
        winnerSelect(event.winners[i]);
      }
    }
  }, []);

  const pageChangeHandler = (event, value) => {
    setPage(value);
  };

  const mediaSelectHandler = (item) => {
    if (mode) {
      winnerSelect(item);
    } else {
      history.push({
        pathname: `/media/${item.id}`,
        state: { item: item, marketplace: false },
      });
    }
  };

  const selectWinnerPhotoHandler = (item) => {
    history.push({
      pathname: `/dashboard/approveReject/${item.id}`,
      state: { item: item, marketplace: false },
    });
  };

  const modeSelectHandler = (newMode) => {
    if (mode && !newMode) {
      winnerReset();
    }
    setMode(newMode);
  };

  const winnerSubmitHandler = () => {
    submitWinnersMutation.mutate({
      eventId: event.id,
      winners: winners.map((w) => w.id),
    });
    setMode(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <EventSummary event={event} />
      {event.endDate && isPast(event.endDate) && (
        <Winners
          onModeSelect={modeSelectHandler}
          winners={winners}
          mode={mode}
          onSubmit={winnerSubmitHandler}
          onSelect={selectWinnerPhotoHandler}
        ></Winners>
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ px: 24 }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => setGridView(true)}
        >
          Grid
        </Button>
        <Button variant="outlined" onClick={() => setGridView(false)}>
          Table{" "}
        </Button>
      </Box>

      {gridView ? (
        <Box>
          <Typography variant="pageHeader">Submissons</Typography>
          <MediaSubmissionStatusSelector
            value={filter}
            onSelect={setFilter}
            stats={data?.data?.stats}
          />

          {data?.data?.totalPages > 1 && (
            <PaginationUI
              page={page}
              totalPages={data?.data?.totalPages}
              handleChange={pageChangeHandler}
            />
          )}
          <MediaList
            media={data?.data?.results}
            onSelect={mediaSelectHandler}
          />
        </Box>
      ) : (
        <Box sx={{ px: 2, my: 3 }}>
          <SubmissionTable onSelect={mediaSelectHandler} event={event} />
        </Box>
      )}

      <ProgressIndicator
        open={
          isLoading ||
          isFetching ||
          isSubmitting ||
          submitWinnersMutation.isLoading
        }
      />

      <InfoAlert
        open={showError}
        title="Error!"
        body={error}
        onClose={() => setShowError(false)}
      />
    </Box>
  );
};

export default EventDetails;
