import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import AuthRoute from "./AuthRoute";

import Events from "../screens/events/Events";
import UserList from "../screens/users/UserList";
import CreateEvent from "../screens/events/CreateEvent";
import MediaDetailsScreen from "../screens/events/MediaDetailsScreen";
import UserDetails from "../screens/users/UserDetails";
import MarketPlace from "../screens/MarketPlace";
import Theme from "../screens/theme/Theme";
import CreateTheme from "../screens/theme/CreateTheme";
import EventDetails from "../screens/events/EventDetails";
import OrdersScreen from "../screens/orders/OrdersScreen";
import OrderDetails from "../screens/orders/OrderDetails";
import ReportsScreen from "../screens/reports/ReportsScreen";

const Routes = () => {
  const match = useRouteMatch();
  const base = match.path || "/";
  console.log(base);

  return (
    <Switch>
      <AuthRoute path={base} exact>
        <Redirect to={`/events`} />
      </AuthRoute>
      <AuthRoute path={`/events`} exact>
        <Events />
      </AuthRoute>
      <AuthRoute path={`/events/create`}>
        <CreateEvent />
      </AuthRoute>
      <AuthRoute path={`/media/:id`}>
        <MediaDetailsScreen />
      </AuthRoute>
      <AuthRoute path={`/media/:id`}>
        <MediaDetailsScreen />
      </AuthRoute>
      <AuthRoute path={`/events/:id/edit`}>
        <CreateEvent />
      </AuthRoute>
      <AuthRoute path={`/events/:id`}>
        <EventDetails />
      </AuthRoute>
      <AuthRoute path={`/users`} exact>
        <UserList />
      </AuthRoute>
      <AuthRoute path={`/users/:id`}>
        <UserDetails />
      </AuthRoute>
      <AuthRoute path={`/marketplace`}>
        <MarketPlace />
      </AuthRoute>
      <AuthRoute path={`/theme/create`}>
        <CreateTheme />
      </AuthRoute>
      <AuthRoute path={`/theme`}>
        <Theme />
      </AuthRoute>
      <AuthRoute path={`/order/:orderId`}>
        <OrderDetails />
      </AuthRoute>
      <AuthRoute path={`/order`}>
        <OrdersScreen />
      </AuthRoute>
      <AuthRoute path={`/reports`}>
        <ReportsScreen />
      </AuthRoute>
    </Switch>
  );
};

export default Routes;
