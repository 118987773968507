import React, { useState, useEffect, useContext } from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import ProgressIndicator from "../../componets/ui/ProgressIndicator";

const SubmissionTable = (props) => {
  const { event } = props;
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [sort, setSort] = useState();

  const { getEventSubmissions } = useContext(APIContext);

  const { data, isLoading, isFetching } = useQuery(
    ["eventMedia", event?.id, page, "all", sort],
    () => getEventSubmissions(event?.id, page + 1, "all", sort),
    {
      keepPreviousData: true,
      enabled: !!event.id,
    }
  );

  useEffect(() => {
    if (data?.data?.totalResults) {
      setRowCount(data?.data?.totalResults);
    }
  }, [data]);

  const pageChangeHandler = (page) => {
    setPage(page);
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.description || "-";
      },
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        const url = params?.row?.url;

        return (
          <Box>
            <img
              src={url}
              style={{ height: 80, width: 80, objectFit: "cover" }}
            />
            ;
          </Box>
        );
      },
    },
    {
      field: "rating",
      headerName: "Image ratings",
      flex: 1,
      valueGetter: (params) =>
        params.row?.rating === undefined ? "-" : params.row?.rating,
    },
    {
      field: "marketPlace",
      headerName: "Approved for Marketplace",
      flex: 1,
    },
    {
      field: "marketPrice",
      headerName: "Marketplace price",
      flex: 1,
      valueGetter: (params) =>
        !params.row?.marketPrice ? "-" : params.row?.marketPrice,
    },
    {
      field: "Likes",
      headerName: "Likes Received",
      flex: 1,
      valueGetter: (params) => params.row?.likes?.length,
    },
    {
      field: "favourites",
      headerName: "Favourites Received",
      flex: 1,
      valueGetter: (params) => params.row?.favourites?.length,
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel?.length === 0) {
      setSort("");
    } else {
      let sortBy =
        sortModel[0]?.sort === "asc"
          ? `-${sortModel[0]?.field}`
          : sortModel[0]?.field;
      setSort(sortBy);
    }
    setPage(0);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={data?.data?.results || []}
        columns={columns}
        rowHeight={100}
        pageSize={10}
        rowCount={rowCount}
        loading={isLoading}
        pagination
        page={page}
        paginationMode="server"
        rowsPerPageOptions={[10]}
        autoPageSize={false}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onPageChange={pageChangeHandler}
        onRowClick={(data) => props?.onSelect(data.row)}
      ></DataGrid>
      <ProgressIndicator open={isLoading} />
    </Box>
  );
};

export default SubmissionTable;
