import React, { useContext, useState } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "react-query";
import { APIContext } from "../service/api-provider";

import { Box } from "@mui/material";
import { Alert } from "@mui/material";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../utils/validate";

import { useDispatch } from "react-redux";
import { setUser, setToken } from "../store/auth";

import LoginCard from "../componets/LoginCard";
import ProgressIndicator from "../componets/ui/ProgressIndicator";

const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const history = useHistory()

  const { login } = useContext(APIContext);
  const loginMutation = useMutation((data) => login(data), {
    onSuccess: (data) => {
      console.log(data);
      dispatch(setUser(data?.data?.user));
      dispatch(setToken(data?.data?.tokens));
      history.push("/")
    },
    onError: (error) => {
      console.log(error?.response);
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error connecting to server. Try again"
      );
    },
  });

  const methods = useForm({
    resolver: yupResolver(loginSchema),
  });

  const submitHandler = async (data) => {
    console.log(data);
    loginMutation.mutate(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 300,
        }}
      >
        <ProgressIndicator open={loginMutation.isLoading} />
        <FormProvider {...methods}>
          <LoginCard onPress={methods.handleSubmit(submitHandler)} />
        </FormProvider>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Box>
  );
};

export default LoginScreen;
