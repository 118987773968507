import React from "react";
import { Route, Switch } from "react-router-dom";

import LoginScreen from "../screens/LoginScreen";
import Home from "../screens/Home";

import AuthRoute from "./AuthRoute";

const BaseRoute = () => {
  return (
    <Switch>
      <Route path="/login">
        <LoginScreen />
      </Route>
      <AuthRoute path="/">
        <Home />
      </AuthRoute>
    </Switch>
  );
};

export default BaseRoute;
