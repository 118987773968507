import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = (props) => {
  const { token } = useSelector((state) => state.auth);
  if (token) {
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};

export default AuthRoute;