import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function ProgressIndicator(props) {
  return (
    <Box boxShadow={4} elevation={2}>
      <Dialog
        open={props.open}
        aria-describedby="alert-dialog-slide-description"
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <DialogContent sx={{ width: 50, height: 50 }}>
          <CircularProgress sx={{ marginLeft: 1, marginTop: 0.5 }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
