import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMutation, useQueryClient } from "react-query";
import { APIContext } from "../../service/api-provider";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { createEventSchema } from "../../utils/validate";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CreateEventForm from "../../componets/events/CreateEventForm";
import DescriptionAlerts from "../../componets/ui/dialogs/DescriptionAlerts";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";

import { calendarDate } from "../../utils/time";

const CreateEvent = () => {
  const history = useHistory();
  const location = useLocation();
  const event = location?.state;

  const [imageFile, setImageFile] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [message, setMessage] = useState();

  const queryClient = useQueryClient();
  const { createEvent, editEvent } = useContext(APIContext);

  const createEventMutation = useMutation((data) => createEvent(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["events"]);
      history.goBack();
    },
    onError: (error) => {
      console.log(error);
      setMessage(error?.response?.data?.message || error?.message);
    },
  });

  const editEventMutation = useMutation(
    ({ data, id }) => editEvent({ data, id }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["events"]);
        history.goBack();
      },
      onError: (error) => {
        console.log(error);
        setMessage(error?.response?.data?.message || error?.message);
      },
    }
  );

  useEffect(() => {
    if (event?.image) {
      setImgData(event.image);
    }
  }, [event]);

  const methods = useForm({
    resolver: yupResolver(createEventSchema),
    defaultValues: event
      ? event
      : {
          startDate: new Date(),
          endDate: new Date(),
        },
  });

  const cancelHandler = () => {
    history.goBack();
  };

  const submitHandler = async (data) => {
    setMessage();
    if (event) {
      editEventHandler(data);
    } else {
      createEventHandler(data);
    }
  };

  const createEventHandler = (data) => {
    data.rules = data.rules.toString("html");
    if (imageFile) {
      data["photo"] = imageFile;
    }

    createEventMutation.mutate(data);
  };

  const editEventHandler = (data) => {
    const dirtyFields = methods.formState.dirtyFields;
    let updatedFields = {};
    for (let key in dirtyFields) {
      if (dirtyFields[key] === true) {
        if (key === "rules") {
          updatedFields[key] = data.rules.toString("html");
        } else {
          updatedFields[key] = data[key];
        }
      }
    }

    if (imageFile) {
      updatedFields["photo"] = imageFile;
    }

    if (Object.keys(updatedFields).length === 0) {
      return;
    }
    editEventMutation.mutate({ data: updatedFields, id: event?.id });
  };

  const fileHandler = (e) => {
    if (e.length > 0) {
      setImageFile(e[0]);
      setImgData(URL.createObjectURL(e[0]));
    }
  };

  return (
    <Box sx={{ ml: 3, mt: 2 }}>
      <Typography variant="pageHeader">Create New Event</Typography>
      <FormProvider {...methods}>
        <CreateEventForm fileHandler={fileHandler} image={imgData} />
      </FormProvider>
      {message && (
        <DescriptionAlerts containerStyle={{ mt: 2 }} message={message} />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
          marginTop: 1,
        }}
      >
        <Button variant="contained" onClick={cancelHandler}>
          Discard
        </Button>
        <Button
          variant="contained"
          onClick={methods.handleSubmit(submitHandler)}
        >
          {event ? "Update" : "Create"}
        </Button>
      </Box>

      <ProgressIndicator
        open={createEventMutation?.isLoading || editEventMutation?.isLoading}
      />
    </Box>
  );
};

export default CreateEvent;
