import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import { styled } from "@mui/material/styles";

import TextInput from "../input/TextInput";
import RichTextInput from "../input/RichTextInput";

const CreateEventForm = (props) => {
  const [eventType, setEventType] = React.useState("");

  const Input = styled("input")({
    display: "none",
  });

  const handleChange = (event) => {
    setEventType(event.target.value);
  };

  let label = "Event Type";

  return (
    <Box>
      <Grid container spacing={10}>
        <Grid item md={7}>
          <TextInput name="title" label="Event Title" type="Text" />
          <TextInput
            name="description"
            label="Description"
            type="Text"
            settings={{
              multiline: true,
              minRows: 4,
            }}
          />

          <TextInput
            name="type"
            label="Event Type"
            settings={{
              select: true,
            }}
          >
            <MenuItem key={"photo"} value={"photo"}>
              Photography
            </MenuItem>
            <MenuItem key={"physicalArt"} value={"physicalArt"}>
              Physical art
            </MenuItem>
            <MenuItem key={"digitalArt"} value={"digitalArt"}>
              Digital art
            </MenuItem>
            <MenuItem key={"vector"} value={"vector"}>
              Vectors
            </MenuItem>
          </TextInput>

          <TextInput
            name="entriesAllowed"
            label="Number of Entries Allowed"
            type="number"
          />

          <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1, marginTop: 2, marginBottom: 1 }}
          >
            Rules
          </Typography>
          <RichTextInput name="rules" />
          <TextInput name="fees" label="Fee" type="text" />
          <TextInput
            name="startDate"
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
          />

          <TextInput
            name="endDate"
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={5}>
          <Box>
            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={(event) => props.fileHandler(event.target.files)}
                />
                <Button variant="contained" component="span">
                  Upload Image
                </Button>
              </label>
            </Box>
            {props.imgData ? (
              <img
                src={props.imgData}
                style={{ height: 380, width: "100%" }}
                alt=""
              />
            ) : props.image ? (
              <img
                src={props.image}
                style={{ height: 380, width: "100%" }}
                alt=""
              />
            ) : (
              <Box sx={{ width: 700 }}></Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateEventForm;
