import React, { forwardRef, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RejectPopup(props) {
  const [rejectReason, setRejectReason] = useState("");

  const handleApproveImage = async () => {
    props.onReject(rejectReason);
    props.close();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-describedby="alert-dialog-slide-description"
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <DialogTitle>{"Reject Reason"}</DialogTitle>
        <DialogContent sx={{ width: 500, height: 100 }}>
          <FormControl component="fieldset">
            <TextField
              label="Enter reason for reject"
              multiline
              rows={2}
              sx={{ width: 500, marginTop: 2 }}
              value={rejectReason}
              onChange={(event) => setRejectReason(event.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close}>Cancel</Button>
          <Button onClick={handleApproveImage}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
