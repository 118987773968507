import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";

import ProgressIndicator from "../../componets/ui/ProgressIndicator";

const columns = [
  { field: "name", headerName: "Name", width: 110 },
  { field: "title", headerName: "Title", width: 150 },
  {
    field: "styles",
    headerName: "Styles",
    width: 600,
    valueGetter: (params) => JSON.stringify(params.value),
  },
];

const Theme = () => {
  const history = useHistory();

  const { getThemes } = useContext(APIContext);
  const { data, isLoading } = useQuery("theme", getThemes);

  const createThemeHandler = () => {
    history.push({
      pathname: `/theme/create`,
    });
  };

  const themeSelectHandler = (data) => {
    console.log(data);
    history.push({
      pathname: `/theme/create`,
      state: data,
    });
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={createThemeHandler}>
          Create Theme
        </Button>
      </Box>
      {data && (
        <DataGrid
          autoHeight
          autoPageSize={false}
          pageSize={10}
          rows={data?.data}
          columns={columns}
          onRowClick={(data) => themeSelectHandler(data.row)}
        />
      )}
      <ProgressIndicator open={isLoading} />
    </Box>
  );
};

export default Theme;
