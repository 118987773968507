import React from "react";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import TextInput from "../input/TextInput";
import { useFormContext } from "react-hook-form";


import ColorIndicator from "./ColorIndicator";
const ColorSelector = (props) => {
  const { watch } = useFormContext();
  const color = watch(props.name);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <TextInput name={props.name} label={props.label} type="Text" />
      <ColorIndicator color={color} />
    </Box>
  );
};

const ThemeForm = (props) => {
  return (
    <Box>
      <Box sx={{ mr: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <TextInput name="name" label="Name" type="Text" />
            <TextInput name="title" label="Title" type="Text" />
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={12}>
            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
              Styles
            </Typography>
          </Grid>
          <Grid item md={4}>
            <ColorSelector name="styles.sidebar" label="SideBar Text Color" />
            <ColorSelector name="styles.toolbar" label="ToolBar Text Color" />
            <ColorSelector
              name="styles.background"
              label="Background Text Color"
            />
            <ColorSelector name="styles.button" label="Button Color" />
            <ColorSelector name="styles.buttonText" label="Button Text Color" />
          </Grid>
          <Grid item md={4}>
            <ColorSelector name="styles.lightText" label="Light Text Color" />
            <ColorSelector
              name="styles.lightestText"
              label="Lightest Text Color"
            />
            <ColorSelector name="styles.mediumText" label="Medium Text Color" />
            <ColorSelector name="styles.accent" label="Accent Text Color" />
          </Grid>
        </Grid>
      </Box>
      
    </Box>
  );
};

export default ThemeForm;
