import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import ProgressIndicator from "../../componets/ui/ProgressIndicator";

function UserList() {
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [sort, setSort] = useState();
  const [query, setQuery] = useState("");

  const match = useRouteMatch();
  const history = useHistory();

  const { getUsers } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["users", page, query, sort],
    () => getUsers(page, query, sort),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data?.data?.totalResults) {
      setRowCount(data?.data?.totalResults);
    }
  }, [data]);

  const onSearch = async (value) => {
    if (value.length > 2) {
      setQuery(value);
    } else {
      setQuery("");
    }
  };

  const userSelectHandler = (user) => {
    history.push({
      pathname: `${match.path}/${user.id}`,
      state: { user },
    });
  };

  const handleSortModelChange = useCallback(
    (sortModel) => {
      if (sortModel?.length === 0) {
        setSort("");
      } else {
        let sortBy =
          sortModel[0]?.sort === "asc"
            ? `-${sortModel[0]?.field}`
            : sortModel[0]?.field;
        setSort(sortBy);
      }
      setPage(0);
    },
    [page, sort]
  );

  const pageChangeHandler = (page) => {
    setPage(page);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "email",
      flex: 1,
    },
    {
      field: "phoneNo",
      headerName: "Phone Number",
      flex: 1,
    },
  ];

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="flex-end">
        <TextField
          id="outlined-basic"
          sx={{ width: "30%" }}
          placeholder="Search Users"
          onChange={(value) => {
            onSearch(value.target.value);
          }}
        />
      </Box>
      <Box sx={{ px: 2, my: 3 }}>
        <DataGrid
          autoHeight
          rows={data?.data?.results || []}
          columns={columns}
          rowCount={rowCount}
          loading={isLoading}
          pagination
          paginationMode="server"
          pageSize={10}
          page={page}
          autoHeight
          rowsPerPageOptions={[10]}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          onPageChange={pageChangeHandler}
          onRowClick={(data) => userSelectHandler(data.row)}
        ></DataGrid>
      </Box>

      <ProgressIndicator open={isLoading} />
    </Box>
  );
}

export default UserList;
