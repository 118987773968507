
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload.access.token;
      state.refreshToken = action.payload.refresh.token;
    },
    logout: (state, action) => {
      state.token = null
      state.refreshToken = null
      state.user = null
    }
  },
});

export default authSlice.reducer;

export const { logout, setToken, setUser} =
  authSlice.actions;
