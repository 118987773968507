import React, { useState, useContext } from "react";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { APIContext } from "../../service/api-provider";

import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import PaginationUI from "../../componets/ui/PaginationUI";
import ReportTable from "../../componets/reports/ReportTable";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";
import ConfirmAlert from "../../componets/ui/dialogs/ConfirmAlert";

import Box from "@mui/material/Box";

const ReportsScreen = () => {
  const [pageNo, setPageNo] = useState(1);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();
  const [showAlert, setAlert] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const { getReports, deleteReport, deleteContent } = useContext(APIContext);
  const queryClient = useQueryClient();

  const handlePageChange = (event, value) => {
    setPageNo(value);
  };

  const { data, isLoading } = useQuery(
    ["reports", pageNo],
    () => getReports(pageNo),
    {
      keepPreviousData: true,
    }
  );

  const deleteReportMutation = useMutation((id) => deleteReport(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reports", pageNo]);
    },
    onError: (error) => {
      console.log(error);
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const deleteContentMutation = useMutation((data) => deleteContent(data), {
    onSuccess: (data, values) => {
      setAlert(false);
      ignoreReportHandler(values?.reportId);
    },
    onError: (error) => {
      setAlert(false);
      console.log(error);
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const ignoreReportHandler = (id) => {
    console.log("ignoreReportHandler", id);
    deleteReportMutation.mutate(id);
  };

  const deleteContentHandler = (item) => {
    let updatedValues = {};

    updatedValues["type"] = deleteItem?.type;
    updatedValues["mediaId"] = deleteItem?.media?.id;
    updatedValues["reportId"] = deleteItem?.id;

    if (deleteItem?.type == "comment") {
      updatedValues["commentId"] = deleteItem?.comment?.id;
    }

    deleteContentMutation.mutate(updatedValues);
  };

  const confirmDelete = (item) => {
    setDeleteItem(item);
    setAlert(true);
  };

  return (
    <Box sx={{ margin: 2 }}>
      <ReportTable
        reportList={data?.data?.results}
        onIgnoreReport={ignoreReportHandler}
        onDeleteContent={confirmDelete}
      />
      {data?.data?.totalPages > 1 && (
        <PaginationUI
          page={pageNo}
          totalPages={data?.data?.totalPages}
          handleChange={handlePageChange}
        />
      )}

      <ConfirmAlert
        open={showAlert}
        onClose={() => setAlert(false)}
        onAgree={deleteContentHandler}
        title={`Delete ${deleteItem?.type}`}
        body={`Are you sure, you want to delete ${deleteItem?.type}?`}
        confirmButton="Delete"
      />
      <InfoAlert
        open={showError}
        title="Error!"
        body={error}
        onClose={() => setShowError(false)}
      />
      <ProgressIndicator open={isLoading || deleteReportMutation.isLoading} />
    </Box>
  );
};

export default ReportsScreen;
