import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../utils/apiConfig";
import {setToken, logout } from "../store/auth";

function providesList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.results.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

let refreshToken = null;

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    refreshToken = getState().auth.refreshToken;
    headers.set("Authorization", `bearer ${token}`);
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data && result?.error?.data?.code === 401) {
    const refreshResult = await baseQuery(
      {
        url: "auth/refresh-tokens",
        method: "POST",
        body: {
          refreshToken: refreshToken,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      api.dispatch(setToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      alert("Login Expired");
      api.dispatch(logout());
    }
  }
  return result;
};

export const apiService = createApi({
  reducerPath: "apiService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Events", "User", "EventPhotos", "Theme"],
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (page = 1) => `event?page=${page}`,
      providesTags: (result) => providesList(result, "Events"),
    }),
    adminLogin: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/admin/login`,
          method: "POST",
          body: {
            email: data.Email,
            password: data.Password,
          },
        };
      },
    }),
    editEvent: builder.mutation({
      query: ({ updatedFields, id, page }) => {
        let body = new FormData();

        for (let key in updatedFields) {
          body.append(key, updatedFields[key]);
        }
        return {
          url: `event/${id}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Events", id: arg.id }],
    }),
    createEvent: builder.mutation({
      query: (data) => {
        let body = new FormData();

        for (let key in data) {
          body.append(key, data[key]);
        }
        return {
          url: `event`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Events"],
    }),
    deleteEvent: builder.mutation({
      query: ({ id }) => {
        return {
          url: `event/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Events"],
    }),
    getEventPhotos: builder.query({
      query: ({ page = 1, id, filter }) =>
        `event/${id}/submissions?page=${page}&q=${
          filter === "all" ? "" : filter
        }`,
      providesTags: (result) => providesList(result, "EventPhotos"),
    }),
    approveSubmission: builder.mutation({
      query: ({ id, rating }) => {
        return {
          url: `media/${id}/approve`,
          method: "PATCH",
          body: {
            rating: rating,
          },
        };
      },
      invalidatesTags: ["EventPhotos"],
    }),
    rejectSubmission: builder.mutation({
      query: ({ id, reason, rejectRating }) => {
        return {
          url: `media/${id}/reject`,
          method: "PATCH",
          body: {
            statusReason: reason,
            rating: rejectRating,
          },
        };
      },
      invalidatesTags: ["EventPhotos"],
    }),
    updateMedia: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `media/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["EventPhotos"],
    }),
    getUserList: builder.query({
      query: ({ pageNo = 1, query }) => `users?page=${pageNo}&q=${query}`,
      providesTags: (result) => providesList(result, "User"),
    }),
    editUser: builder.mutation({
      query: ({ updatedFields, userId }) => {
        let body = new FormData();
        for (let key in updatedFields) {
          body.append(key, updatedFields[key]);
        }
        return {
          url: `users/${userId}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getMarketPlace: builder.query({
      query: ({ page = 1, filter }) =>
        `media/marketplace?page=${page}&q=${filter === "all" ? "" : filter}`,
      providesTags: (result) => providesList(result, "EventPhotos"),
    }),
    getTheme: builder.query({
      query: () => "theme",
      providesTags: (result) => {
        return result
          ? [
              { type: "Theme", id: "LIST" },
              ...result.map(({ id }) => ({ type: "Theme", id })),
            ]
          : [{ type: "Theme", id: "LIST" }];
      },
    }),
    createTheme: builder.mutation({
      query: (data) => {
        return {
          url: "theme",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "Theme", id: "LIST" }] : []),
    }),
    updateTheme: builder.mutation({
      query: ({id, data}) => {
        return {
          url: `theme/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, args) => (result ? [{ type: "Theme", id:  args.id}] : []),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useEditEventMutation,
  useCreateEventMutation,
  useGetUserListQuery,
  useGetEventPhotosQuery,
  useApproveSubmissionMutation,
  useRejectSubmissionMutation,
  useUpdateMediaMutation,
  useDeleteEventMutation,
  useEditUserMutation,
  useGetMarketPlaceQuery,
  useAdminLoginMutation,
  useGetThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation
} = apiService;
