import { useReducer } from "react";

const reducer = (state, action) => {
  if (action.type === "insert") {
    return [...state, action.item];
  } else if (action.type === "remove") {
    const filtered = state.filter((i) => i.id !== action.item.id);
    return filtered;
  } else if (action.type === "select") {
    const index = state.findIndex((s) => s.id === action.item.id);
    if (index < 0) {
      return [...state, action.item];
    } else {
      const filtered = state.filter((i) => i.id !== action.item.id);
      return filtered;
    }
  } else if (action.type === "reset") {
    return [];
  }
};

const useWinnerSelector = (values = []) => {
  const [winners, dispatch] = useReducer(reducer, values);

  const onSelect = (item) => {
    console.log("Select item ", item)
    dispatch({
      type: "select",
      item: item,
    });
  };

  const onCancel = (item) => {
    dispatch({
      type: "reset",
    });
  };

  return [winners, onSelect, onCancel];
};

export default useWinnerSelector;
