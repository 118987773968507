import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../input/TextInput";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import blankImage from "../../assets/blankProfileImage.png";

import "../../css/Form.css";

const EditUserForm = (props) => {
  const Input = styled("input")({
    display: "none",
  });

  return (
    <div className="form">
      <Grid container>
        <Grid item md={3}>
          <img
            src={props.img || blankImage}
            style={{ height: 200, width: 200, borderRadius: 140 }}
            alt=""
          />
        </Grid>
        <Grid item md={4}>
          <Box sx={{ marginTop: 20 }}>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={(event) => props.filerHandler(event.target.files)}
              />
              <Button variant="contained" component="span">
                Change Photo
              </Button>
            </label>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={4}>
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }}
          >
            Account Information
          </Typography>
          <TextInput
            name="name"
            label="Name"
            type="Text"
            style={{ width: 340 }}
            errorMessage="Name is a required field"
          />
          <TextInput
            name="role"
            label="Role"
            type="Text"
            style={{ width: 340 }}
            errorMessage="Role is a required field"
          />
          <TextInput
            name="username"
            label="User Name"
            type="Text"
            style={{ width: 340 }}
            errorMessage="User Name is a required field"
            errorMsg={props.errorMessageUsername}
          />
          <TextInput
            name="email"
            label="Email"
            type="Text"
            style={{ width: 340 }}
            errorMessage="Email Name is a required field"
            errorMsg={props.errorMessageEmail}
          />
          <TextInput
            name="phoneNo"
            label="Phone No"
            type="Text"
            style={{ width: 340 }}
            errorMessage="Phone No is a required field"
            errorMsg={props.errorMessagePhoneNo}
          />
        </Grid>
        <Grid item md={4}>
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }}
          >
            Change Password
          </Typography>
          <TextInput
            name="password"
            label="Change Password"
            type="password"
            style={{ width: 340 }}
          />
          <TextInput
            name="cpassword"
            label="Confirm Password"
            type="password"
            style={{ width: 340 }}
            errorMessage="Password does not match"
          />
        </Grid>
        <Grid item md={4}>
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }}
          >
            Address Information
          </Typography>
          <TextInput
            name="address"
            label="address"
            type="Text"
            style={{ width: 340 }}
          />
          <TextInput
            name="city"
            label="City"
            type="Text"
            style={{ width: 340 }}
          />
          <TextInput
            name="state"
            label="State"
            type="Text"
            style={{ width: 340 }}
          />
        </Grid>
      </Grid>

      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          m: "auto",
          width: "fit-content",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
        }}
      >
        <Button variant="contained" onClick={props?.onDiscard}>
          Discard
        </Button>
        <Button variant="contained" onClick={props.onSubmit}>
          Save
        </Button>
      </Box>
    </div>
  );
};

export default EditUserForm;
