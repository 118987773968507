import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup.string().trim().email("Invalid Email").required(),
  password: yup.string().required(),
});

const userDetailsSchema = yup.object().shape({
  name: yup.string().trim().required(),
  email: yup.string().trim().email("Invalid Email").required(),
  username: yup.string().trim().required(),
  password: yup.string(),
  cpassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  phoneNo: yup.string().trim().required(),
  address: yup.string().trim(),
  city: yup.string().trim(),
  state: yup.string().trim(),
});

const createEventSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  description: yup.string().trim().required("Description is required"),
  fees: yup.number().required("Fees is required"),
  type: yup.string().required("Field is required"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup.date().required("End Date is required"),
});

const themeSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  title: yup.string().trim().required("Title is required"),
  styles: yup.object().shape({
    lightText: yup.string().trim().required("Field is required"),
    lightestText: yup.string().trim().required("Field is required"),
    mediumText: yup.string().trim().required("Field is required"),
    accent: yup.string().trim().required("Field is required"),
    toolbar: yup.string().trim().required("Field is required"),
    button: yup.string().trim().required("Field is required"),
    buttonText: yup.string().trim().required("Field is required"),
    sidebar: yup.string().trim().required("Field is required"),
    border: yup.string().trim().required("Field is required"),
  }),
});

export { loginSchema, userDetailsSchema, createEventSchema, themeSchema };
