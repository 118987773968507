import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useQuery } from "react-query";
import { APIContext } from "../service/api-provider";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

import ProgressIndicator from "../componets/ui/ProgressIndicator";
import PaginationUI from "../componets/ui/PaginationUI";
import MediaList from "../componets/events/MediaList";
import MarketPlaceTable from "../componets/MarketPlaceTable";
import { MarketPlaceStatusSelector } from "../componets/events/CheckBoxGroup";

function MarketPlace() {
  const history = useHistory();
  const location = useLocation();
  const [pageNo, setPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [gridView, setGridView] = useState(true);

  const { getMarketPlace } = useContext(APIContext);

  const { data, isLoading, isFetching } = useQuery(
    ["marketplace", pageNo, filter],
    () => getMarketPlace(pageNo, filter),
    { keepPreviousData: true }
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filterChangeHandler = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const mediaSelectHandler = (item) => {
    history.push({
      pathname: `/media/${item.id}`,
      state: { item: item, marketplace: true },
    });
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="pageHeader" sx={{ px: 2 }}>
        MarketPlace
      </Typography>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ px: 24 }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => setGridView(true)}
        >
          Grid
        </Button>
        <Button variant="outlined" onClick={() => setGridView(false)}>
          Table{" "}
        </Button>
      </Box>
      {gridView ? (
        <Box>
          <MarketPlaceStatusSelector
            value={filter}
            onSelect={filterChangeHandler}
            stats={data?.data?.stats}
          />
          {data?.data?.results && data?.data?.totalPages > 1 && (
            <PaginationUI
              page={pageNo}
              totalPages={data?.data?.totalPages}
              handleChange={handlePageChange}
            />
          )}

          <MediaList
            media={data?.data?.results}
            onSelect={mediaSelectHandler}
          />
        </Box>
      ) : (
        <Box sx={{ px: 2, my: 3 }}>
          <MarketPlaceTable
            data={data?.data?.results}
            onSelect={mediaSelectHandler}
          />
        </Box>
      )}

      <ProgressIndicator open={isLoading || isFetching} />
    </Box>
  );
}

export default MarketPlace;
