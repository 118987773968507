import React, { createContext, useCallback, useMemo } from "react";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { store } from "../store/store";

import { useDispatch } from "react-redux";
import { setToken, logout } from "../store/auth";

import { baseURL } from "../utils/apiConfig";

const APIContext = createContext();
const { Provider } = APIContext;

const headers = {
  "Content-Type": "application/json",
};

const APIProvider = ({ children }) => {
  const dispatch = useDispatch();

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: baseURL,
        headers: headers,
      }),
    []
  );

  const getToken = () => {
    const token = store.getState().auth.token;
    return token;
  };

  const getRefreshToken = () => {
    const token = store.getState().auth.refreshToken;
    return token;
  };

  axiosInstance.interceptors.request.use((request) => {
    const token = getToken();
    if (token) {
      request.headers["Authorization"] = `bearer ${getToken()}`;
    }
    return request;
  });

  const refreshAuthLogic = async (failedRequest) => {
    try {
      const response = await axiosInstance.post(
        "auth/refresh-tokens",
        {
          refreshToken: getRefreshToken(),
        },
        { skipAuthRefresh: true }
      );
      dispatch(setToken(response?.data));
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + response?.data?.access?.token;
      return Promise.resolve();
    } catch (err) {
      console.log("Interceptor error ", err?.response);
      if (err?.response.status === 401) {
        dispatch(logout());
      }
      throw err;
    }
  };

  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

  const login = useCallback(
    (body) => {
      return axiosInstance.post("auth/admin/login", body);
    },
    [axiosInstance]
  );

  const getEvents = useCallback(
    (pageNo, sort) => {
      let path = `event?page=${pageNo}&limit=12`;
      if (sort) {
        path += `&sort=${sort}`;
      }
      return axiosInstance.get(path);
    },
    [axiosInstance]
  );

  const deleteEvent = useCallback(
    (id) => {
      return axiosInstance.delete(`event/${id}`);
    },
    [axiosInstance]
  );

  const createEvent = useCallback(
    (data) => {
      const body = new FormData();
      for (let key in data) {
        if (data[key] !== undefined) {
          body.append(key, data[key]);
        }
      }

      return axiosInstance.post("event", body);
    },
    [axiosInstance]
  );

  const editEvent = useCallback(
    ({ data, id }) => {
      const body = new FormData();
      for (let key in data) {
        body.append(key, data[key]);
      }
      return axiosInstance.post(`event/${id}`, body);
    },
    [axiosInstance]
  );

  const getEventSubmissions = useCallback(
    (id, page, query, sort) => {
      const q = query === "all" ? "" : query;

      let path = `event/${id}/submissions?page=${page}`;

      if (q) {
        path += `&q=${q}`;
      }

      if (sort) {
        path += `&sort=${sort}`;
      }

      console.log("sort", path);
      return axiosInstance.get(path);
    },
    [axiosInstance]
  );

  const submitEventWinners = useCallback((eventId, winners) => {
    return axiosInstance.patch(`event/${eventId}`, { winners });
  });

  const approveSubmission = useCallback(
    (id, rating) => {
      return axiosInstance.patch(`media/${id}/approve`, {
        rating,
      });
    },
    [axiosInstance]
  );

  const rejectSubmission = useCallback(
    (id, reason, rating) => {
      return axiosInstance.patch(`media/${id}/reject`, {
        statusReason: reason,
        rating: rating,
      });
    },
    [axiosInstance]
  );

  const acceptPrice = useCallback(
    (id) => {
      return axiosInstance.patch(`media/${id}/adminAccept`);
    },
    [axiosInstance]
  );

  const rejectPrice = useCallback(
    (id) => {
      return axiosInstance.patch(`media/${id}/adminReject`);
    },
    [axiosInstance]
  );

  const updateMedia = useCallback(
    (id, body) => {
      return axiosInstance.patch(`media/${id}`, body);
    },
    [axiosInstance]
  );

  const getMarketPlace = useCallback(
    (pageNo, filter, sort) => {
      const q = filter === "all" ? "" : filter;
      let path = `media/marketplace?page=${pageNo}&limit=12`;

      if (q) {
        path += `&q=${q}`;
      }

      if (sort) {
        path += `&sort=${sort}`;
      }

      console.log(path);

      return axiosInstance.get(path);
    },
    [axiosInstance]
  );

  const getUsers = useCallback(
    (pageNo, filter, sort) => {
      let path = `users?page=${pageNo + 1}`;

      if (filter) {
        path += `&q=${filter}`;
      }

      if (sort) {
        path += `&sort=${sort}`;
      }
      return axiosInstance.get(path);
    },
    [axiosInstance]
  );

  const updateUser = useCallback(
    (userId, data) => {
      let body = new FormData();
      for (let key in data) {
        body.append(key, data[key]);
      }
      return axiosInstance.patch(`users/${userId}`, body);
    },
    [axiosInstance]
  );

  const getUserSubmissions = useCallback((userId, pageNo) => {
    const path = `media/submissions/${userId}?page=${pageNo}`
    return axiosInstance.get(path);
  }, [axiosInstance]);

  const getThemes = useCallback(() => {
    return axiosInstance.get("theme");
  }, [axiosInstance]);

  const createTheme = useCallback(
    (data) => {
      return axiosInstance.post("theme", data);
    },
    [axiosInstance]
  );

  const updateTheme = useCallback(
    (id, data) => {
      return axiosInstance.post(`theme/${id}`, data);
    },
    [axiosInstance]
  );

  const deleteTheme = useCallback(
    (id) => {
      return axiosInstance.delete(`theme/${id}`);
    },
    [axiosInstance]
  );

  const getOrders = useCallback(
    (page, query) => {
      return axiosInstance.get(`order/admin?page=${page}&q=${query}`);
    },
    [axiosInstance]
  );

  const userAction = useCallback(
    ({ id, action }) => {
      return axiosInstance.post(`users/${id}/block/${action}`);
    },
    [axiosInstance]
  );

  const getReports = useCallback(
    (pageNo) => {
      return axiosInstance.get(`report?page=${pageNo}`);
    },
    [axiosInstance]
  );

  const deleteReport = useCallback(
    (id) => {
      return axiosInstance.delete(`report/${id}`);
    },
    [axiosInstance]
  );

  const deleteContent = useCallback(
    ({ mediaId, type, commentId }) => {
      let url = "";
      if (type === "media") {
        url = `${type}/${mediaId}`;
      }

      if (type === "comment") {
        url = `media/${mediaId}/${type}/${commentId}`;
      }

      return axiosInstance.delete(url);
    },
    [axiosInstance]
  );

  const apis = {
    login,
    getEvents,
    deleteEvent,
    createEvent,
    editEvent,
    submitEventWinners,
    getEventSubmissions,
    approveSubmission,
    rejectSubmission,
    getUserSubmissions,
    acceptPrice,
    rejectPrice,
    updateMedia,
    getMarketPlace,
    getUsers,
    updateUser,
    getThemes,
    createTheme,
    updateTheme,
    deleteTheme,
    getOrders,
    userAction,
    getReports,
    deleteReport,
    deleteContent,
  };

  return <Provider value={apis}>{children}</Provider>;
};

export { APIContext, APIProvider };
