import Button from "@mui/material/Button";
import TextInput from "./input/TextInput";

import { Box } from "@mui/material";
import welcomeIcon from "../assets/welcomeAnimation.gif";
import "../css/LoginCard.css";

function LoginCard(props) {
  return (
    <Box>
      <img
        src={welcomeIcon}
        alt="new"
        style={{ height: 300, width: 300, borderRadius: 140, alignSelf: 'center' }}
      />
      <TextInput
        name="email"
        label="Email"
        type="Email"
      />
      <TextInput
        name="password"
        label="Password"
        type="Password"
      />
      <Button
      sx={{marginRight: 0, marginLeft: 0, width: '100%' }}
        variant="contained"
        onClick={props.onPress}
      >
        Sign in
      </Button>
    </Box>
  );
}

export default LoginCard;
