import React, { useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import OrderTable from "../../componets/orders/OrderTable";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import PaginationUI from "../../componets/ui/PaginationUI";

import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

const OrdersScreen = () => {
  const [query, setQuery] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const match = useRouteMatch();
  const history = useHistory();

  const { getOrders } = useContext(APIContext);
  const { data, isLoading } = useQuery(
    ["orders", pageNo, query],
    () => getOrders(pageNo, query),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (event, value) => {
    setPageNo(value);
  };

  const searchHandler = (e) => {
    //Debounce the keyboard
    const q = e.target?.value
    if (q?.length < 3) {
      setQuery("")
      setPageNo(1)
      return
    } 
    setTimeout(() => {
      setQuery(e.target.value)
      setPageNo(1)
    }, 1000)
  }

  const orderSelectHandler = (order) => {
    history.push({
      pathname: `${match.path}/${order.id}`,
      state: order,
    });
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Box display="flex" justifyContent="flex-end">
        <TextField
          id="outlined-basic"
          sx={{ width: "30%", mb: 2 }}
          placeholder="Search"
          onChange={searchHandler}
        />
      </Box>

      <OrderTable data={data?.data?.results} onSelect={orderSelectHandler} />
      {data?.data?.totalPages > 1 && (
        <PaginationUI
          page={pageNo}
          totalPages={data?.data?.totalPages}
          handleChange={handlePageChange}
        />
      )}
      <ProgressIndicator open={isLoading} />
    </Box>
  );
};

export default OrdersScreen;
