import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import { formattedDate } from "../../utils/time";

const MediaCard = ({ item, onClick, highlight }) => {
  return (
    <Card
      onClick={() => onClick(item)}
      sx={{
        width: 279,
        marginTop: 3,
        marginRight: 1,
        marginBottom: 1,
        marginLeft: 1,
        opacity: highlight ? 0.6 : 1,
      }}
    >
      <CardMedia component="img" height="194" image={item.url} />
      <CardContent>
        <Typography variant="h6">
          {item?.description === "undefined" ? "" : item?.description}
        </Typography>
        <Typography variant="body2">{"Serial No: " + item.serialNo}</Typography>
        <Typography variant="body2">{item.user}</Typography>
        <Typography variant="body2">{item.tags}</Typography>
        <Typography variant="body2">{formattedDate(item.date)}</Typography>
        <Rating disabled={true} value={item.rating} />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FavoriteBorderIcon fontSize="small" sx={{ mr: 1 }} />
            {item.likeCount || 0}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StarBorderIcon fontSize="small" sx={{ mr: 1 }} />
            {item.favCount || 0}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default MediaCard
