import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import MediaCard from "../events/MediaCard";
import DescriptionAlerts from "./dialogs/DescriptionAlerts";

import Colors from "../../constants/colors";

const Winners = (props) => {
  return (
    <Box sx={{mt: 2, mb: 2}}>
      <Paper sx={{ pl: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" component="div">
            Winners
          </Typography>
          {!!props?.winners && (
            <Button
              variant="contained"
              className="Button"
              size="large"
              style={{
                margin: 10,
                color: "white",
                backgroundColor: Colors.backgroundColor,
              }}
              onClick={() => props?.onModeSelect(!props.mode)}
            >
              {props.mode ? "Cancel" : "Select Winner"}
            </Button>
          )}
        </Box>
        {props.winners?.length > 0 ? (
          <Grid container spacing={1}>
            {props.winners.map((item) => (
              <MediaCard
                item={item}
                key={item.id}
                onClick={() => props?.onSelect(item)}
              />
            ))}
          </Grid>
        ) : (
          <DescriptionAlerts message={"No Winners Selected Yet"} />
        )}
        {props?.mode && props.winners?.length > 0 && (
          <Button
            variant="contained"
            className="Button"
            size="large"
            style={{
              margin: 10,
              color: "white",
              backgroundColor: Colors.backgroundColor,
            }}
            onClick={props.onSubmit}
          >
            Submit
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default Winners;
