import { Controller, useFormContext, get } from "react-hook-form";
import { RichTextEditor } from '@mantine/rte';
import Box from "@mui/material/Box";

function RichTextInput({ name }) {
  const {
    control,
    formState: { errors },
    defaultValue,
  } = useFormContext();

  const error = get(errors, name);
  console.log(error);

  console.log("Default Value ", defaultValue);

  const initialValue = "";

  return (
    <Box>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <RichTextEditor
              value={value}
              onChange={onChange}
              sx={{
                minHeight: 250
              }}
            />
          );
        }}
        name={name}
        defaultValue={initialValue}
      />
    </Box>
  );
}

export default RichTextInput;
