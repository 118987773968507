import Color from "../constants/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            height: 45,
            minWidth: 150,
            margin: 10,
            padding: 20,
            color: "white",
            backgroundColor: Color.backgroundColor,
            "&:hover": {
              backgroundColor: Color.secondary,
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "pageHeader" },
          style: {
            fontSize: 24,
          },
        },
      ],
    },
    MuiTableCell: {
      variants: [
        {
          props: { variant: "title" },
          style: {
            fontWeight: "bold",
          },
        },
      ],
    },
  },
});

export default theme;
