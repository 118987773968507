import React from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckBoxGroup = ({ items, value, onSelect }) => {
  const changeHandler = (event) => {
    onSelect(event.target.name);
  };

  return (
    <FormGroup row={true} sx={{ mt: 2 }}>
      {items?.map((item) => {
        return (
          <FormControlLabel
            control={<Checkbox />}
            name={item.name}
            label={item.label}
            checked={value === item.name}
            onChange={changeHandler}
          />
        );
      })}
    </FormGroup>
  );
};

const MediaSubmissionStatusSelector = (props) => {
  const items = [
    {
      name: "pending",
      label: `Pending (${props?.stats?.pending || 0})`,
    },
    {
      name: "approved",
      label: `Approved (${props?.stats?.approved || 0})`,
    },
    {
      name: "rejected",
      label: `Rejected (${props?.stats?.rejected || 0})`,
    },
    {
      name: "all",
      label: `All (${props?.stats?.total || 0})`,
    },
  ];
  return (
    <CheckBoxGroup
      items={items}
      onSelect={props.onSelect}
      value={props.value}
    ></CheckBoxGroup>
  );
};

const MarketPlaceStatusSelector = (props) => {
  const items = [
    {
      name: "accepted",
      label: `Live (${props?.stats?.live || 0})`,
    },
    {
      name: "userRejected",
      label: `User Rejected (${props?.stats?.userRejected || 0})`,
    },
    {
      name: "adminRejected",
      label: `Admin Rejected (${props?.stats?.adminRejected || 0})`,
    },
    {
      name: "pending",
      label: `Pending User Acceptance (${props?.stats?.pending || 0})`,
    },
    {
      name: "deleted",
      label: `Deleted (${props?.stats?.deleted || 0})`,
    },
    {
      name: "all",
      label: `All (${props?.stats?.total})`,
    },
  ];
  return (
    <CheckBoxGroup
      items={items}
      onSelect={props.onSelect}
      value={props.value}
    ></CheckBoxGroup>
  );
};

export default CheckBoxGroup;

export { MediaSubmissionStatusSelector, MarketPlaceStatusSelector };
