import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { useMutation } from "react-query";
import { APIContext } from "../../service/api-provider";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import RejectPopup from "../../componets/ui/RejectPopup";
import MediaDetails from "../../componets/media/MediaDetails";
import MediaExifData from "../../componets/media/MediaExifData";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";
import ConfirmAlert from "../../componets/ui/dialogs/ConfirmAlert";
import MediaActions from "../../componets/media/MediaActions";

import { Button } from "@mui/material";

const MediaDetailsScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();
  const [marketPlace, setMarketplace] = useState();
  const [rejectRating, setRejectRating] = useState();
  const [showError, setShowError] = useState(false);
  const [errorTitle, setErrorTitle] = useState();
  const [showAlert, setAlert] = useState(false);
  const [error, setError] = useState();
  const { id } = useParams();

  useEffect(() => {
    const item = location.state.item;
    const marketplace = location.state.marketplace;
    setItem(item);
    setMarketplace(marketplace);
  }, [location]);

  const {
    approveSubmission,
    rejectSubmission,
    updateMedia,
    acceptPrice,
    rejectPrice,
    deleteContent,
  } = useContext(APIContext);

  const approveMutation = useMutation(
    ({ id, rating }) => approveSubmission(id, rating),
    {
      onSuccess: (data) => {
        console.log(data);
        setItem(data?.data);
      },
      onError: (error) => {
        console.log("Error Here ", error?.response);
        setErrorTitle("Error!");
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error Connecting to Server. Try again."
        );
        setShowError(true);
      },
    }
  );

  const rejectMutation = useMutation(
    ({ id, reason, rating }) => rejectSubmission(id, reason, rating),
    {
      onSuccess: (data) => {
        console.log(data);
        setItem(data?.data);
      },
      onError: (error) => {
        console.log(error);
        setErrorTitle("Error!");
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error Connecting to Server. Try again."
        );
        setShowError(true);
      },
    }
  );

  const updateMutation = useMutation(({ id, data }) => updateMedia(id, data), {
    onSuccess: (data) => {
      console.log(data);
      setItem(data?.data);
    },
    onError: (error) => {
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const priceAcceptMutation = useMutation((id) => acceptPrice(id), {
    onSuccess: (data) => {
      console.log(data);
      setItem(data?.data);
    },
    onError: (error) => {
      setErrorTitle("Error!");
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const priceRejectMutation = useMutation((id) => rejectPrice(id), {
    onSuccess: (data) => {
      console.log(data);
      setItem(data?.data);
    },
    onError: (error) => {
      setErrorTitle("Error!");
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const deleteContentMutation = useMutation((data) => deleteContent(data), {
    onSuccess: (data) => {
      setAlert(false);
      setErrorTitle("Success");
      setError(`Media ${item?.isDeleted ? 'restored': 'deleted'} Successfully`);
      setShowError(true);
    },
    onError: (error) => {
      console.log(error);
      setErrorTitle("Error!");
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const approveHandler = (rating) => {
    approveMutation.mutate({
      id,
      rating,
    });
  };

  const rejectHandler = (reason) => {
    rejectMutation.mutate({
      id,
      reason,
      rejectRating,
    });
  };

  const saveHandler = (data) => {
    updateMutation.mutate({ id, data });
  };

  const priceAcceptHandler = () => {
    priceAcceptMutation.mutate(item.id);
  };

  const priceRejectHandler = () => {
    priceRejectMutation.mutate(item.id);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const openPopup = (rating) => {
    setRejectRating(rating);
    setOpen(true);
  };

  const deleteHandler = () => {
    deleteContentMutation.mutate({
      mediaId: item?.id,
      type: "media",
    });
  };

  if (!item) return null;

  return (
    <Box sx={{ marginTop: 1 }}>
      <Box sx={{ mb: 2 }}>
        <img
          src={item?.url}
          height="400"
          width="100%"
          style={{ objectFit: "contain" }}
          alt=""
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
        <Button variant="contained" onClick={() => setAlert(true)}>
          {item?.isDeleted ? "Undo Delete" : "Delete"}
        </Button>
      </Box>
      <Grid container spacing={1} sx={{ padding: 1 }}>
        <Grid item xs={6}>
          {item && (
            <MediaDetails
              data={item}
              onApprove={approveHandler}
              onReject={openPopup}
              marketplace={marketPlace}
            />
          )}
          {!marketPlace && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              {item && item.status === "approved" && (
                <MediaActions
                  data={item}
                  onSave={saveHandler}
                  onAccept={priceAcceptHandler}
                  onReject={priceRejectHandler}
                />
              )}
            </Grid>
          )}
        </Grid>
        {marketPlace ? (
          <Grid item xs={6}>
            {item && item.status === "approved" && (
              <MediaActions
                data={item}
                onSave={saveHandler}
                onAccept={priceAcceptHandler}
                onReject={priceRejectHandler}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={6}>
            {item?.exif && <MediaExifData data={item} />}
          </Grid>
        )}
      </Grid>
      <RejectPopup open={open} close={closePopup} onReject={rejectHandler} />
      <InfoAlert
        open={showError}
        title={errorTitle}
        body={error}
        onClose={() => setShowError(false)}
      />
      <ConfirmAlert
        open={showAlert}
        onClose={() => setAlert(false)}
        onAgree={deleteHandler}
        title="Delete"
        body={`Are you sure, you want to ${item?.isDeleted ? 'Undo Delete' : 'Delete'}`}
        confirmButton="Confirm"
      />
      <ProgressIndicator
        open={
          approveMutation.isLoading ||
          rejectMutation.isLoading ||
          updateMedia.isLoading ||
          priceAcceptMutation.isLoading ||
          priceRejectMutation.isLoading
        }
      />
    </Box>
  );
};

export default MediaDetailsScreen;
