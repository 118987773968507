import { Controller, useFormContext, get } from "react-hook-form";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { calendarDate } from "../../utils/time";

function TextInput(props) {
  const {
    name,
    label,
    type,
    errorMessage,
    errorMsg,
    style,
    InputLabelProps,
    settings,
  } = props;

  const {
    control,
    formState: { errors },
    defaultValue,
  } = useFormContext();

  const error = get(errors, name);

  return (
    <Box>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            id="outlined-basic"
            label={label}
            onChange={onChange}
            value={type === "date" ? calendarDate(value) : value}
            InputLabelProps={InputLabelProps}
            name={name}
            type={type}
            variant="outlined"
            fullWidth
            {...settings}
            style={{ marginTop: 10, marginBottom: 2, ...style }}
          >
            {props?.children}
          </TextField>
        )}
        name={name}
        defaultValue={defaultValue}
      />
      {error && (
        <Typography
          variant="p"
          component="div"
          sx={{ flexGrow: 1, color: "red", mb: 1 }}
        >
          {error?.message || errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export default TextInput;
