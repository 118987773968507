import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";

const EventCard = (props) => {
  const event = props?.event;

  return (
    <Card sx={{ width: 290 }} style={{ marginTop: 20, marginLeft: 10 }}>
      <CardActionArea onClick={props?.onPress}>
        <CardMedia
          component="img"
          height="140"
          image={event?.image}
          sx={{
            background: "lightgray",
          }}
        />
        <CardContent sx={{ height: 67, overflow: "hidden" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            noWrap={true}
            style={{ wordWrap: "break-word" }}
          >
            {event?.serialNo + ". " + event?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {event?.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button onClick={props?.onEditPress} size="small">
          Edit
        </Button>
        <Button size="small" onClick={props?.onDeletePress}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default EventCard;
