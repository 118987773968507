import React, { useState, useEffect, useContext } from "react";
import {useHistory, useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OrderSummary from "../../componets/orders/OrderSummary";

const OrderDetails = () => {
  const history = useHistory();
  const location = useLocation()
  const order = location?.state;
  console.log("Order Details", order)

  return (
    <Box sx={{ padding: 2 }}>
        <OrderSummary order={order} />
    </Box>
  );
};

export default OrderDetails;
