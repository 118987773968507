import React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { bytesToSize } from "../../utils/file";

function MediaExifData(props) {
  const data = props.data;
  console.log("Exif", data.exif);

  //const filesize = bytesToSize(data?.exif?.size);

  console.log(data?.exif);

  return (
    <Box>
      <Typography variant="pageHeader">Exif</Typography>
      <TableContainer component={Paper} sx={{mt: 1}}>
        <Table aria-label="simple table">
          <TableBody>
            {data.exif?.ImageWidth && (
              <TableRow>
                <TableCell variant="title">Resoultion</TableCell>
                <TableCell>
                  {data.exif?.ImageLength} x {data.exif?.ImageWidth}
                </TableCell>
              </TableRow>
            )}
            {data?.exif?.image && (
              <TableRow>
                <TableCell variant="title">Camera Model</TableCell>
                <TableCell>
                  {data?.exif?.Make} {data?.exif?.Model}
                </TableCell>
              </TableRow>
            )}
            {data?.exif?.size && (
              <TableRow>
                <TableCell variant="title">File Size</TableCell>
                <TableCell>{bytesToSize(data?.exif?.size)}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ApertureValue && (
              <TableRow>
                <TableCell variant="title">Aperture Value</TableCell>
                <TableCell>{data?.exif?.ApertureValue}</TableCell>
              </TableRow>
            )}
            {data?.exif?.MaxApertureValue && (
              <TableRow>
                <TableCell variant="title">Max Aperture Value</TableCell>
                <TableCell>{data?.exif?.MaxApertureValue}</TableCell>
              </TableRow>
            )}
            {data?.exif?.BrightnessValue && (
              <TableRow>
                <TableCell variant="title">Brightness Value</TableCell>
                <TableCell>{data?.exif?.BrightnessValue}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ExposureTime && (
              <TableRow>
                <TableCell variant="title">Exposure Time</TableCell>
                <TableCell>{data?.exif?.ExposureTime}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ExposureProgram != null && (
              <TableRow>
                <TableCell variant="title">Exposure Program</TableCell>
                <TableCell>{data?.exif?.ExposureProgram}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ExposureMode != null && (
              <TableRow>
                <TableCell variant="title">Exposure Mode</TableCell>
                <TableCell>{data?.exif?.ExposureMode}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ExposureBiasValue != null && (
              <TableRow key="exposureBiasValue">
                <TableCell variant="title">Exposure Bias Value</TableCell>
                <TableCell>{data?.exif?.ExposureBiasValue}</TableCell>
              </TableRow>
            )}
            {data?.exif?.FNumber != null && (
              <TableRow key="fNumber">
                <TableCell variant="title">F Number</TableCell>
                <TableCell>{data?.exif?.FNumber}</TableCell>
              </TableRow>
            )}
            {data?.exif?.MeteringMode != null && (
              <TableRow key="meteringMode">
                <TableCell variant="title">Metering Mode</TableCell>
                <TableCell>{data?.exif?.MeteringMode}</TableCell>
              </TableRow>
            )}
            {data?.exif?.WhiteBalance != null && (
              <TableRow key="meteringMode">
                <TableCell variant="title">White Balance</TableCell>
                <TableCell>{data?.exif?.WhiteBalance}</TableCell>
              </TableRow>
            )}
            {data?.exif?.Flash != null && (
              <TableRow key="flash">
                <TableCell variant="title">Flash</TableCell>
                <TableCell>{data?.exif?.Flash}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ShutterSpeedValue != null && (
              <TableRow key="shutterSpeed">
                <TableCell variant="title">Shutter Speed Value</TableCell>
                <TableCell>{data?.exif?.ShutterSpeedValue}</TableCell>
              </TableRow>
            )}
            {data?.exif?.FocalLength != null && (
              <TableRow key="focalLength">
                <TableCell variant="title">Focal Length</TableCell>
                <TableCell>{data?.exif?.FocalLength}</TableCell>
              </TableRow>
            )}
            {data?.exif?.ColorSpace != null && (
              <TableRow key="colorSpace">
                <TableCell variant="title">Color Space</TableCell>
                <TableCell>{data?.exif?.ColorSpace}</TableCell>
              </TableRow>
            )}
            {data?.exif?.LightSource != null && (
              <TableRow key="lightSource">
                <TableCell variant="title">Light Source</TableCell>
                <TableCell>{data?.exif?.LightSource}</TableCell>
              </TableRow>
            )}
            {data?.exif?.Make != null && (
              <TableRow key="make">
                <TableCell variant="title">Make</TableCell>
                <TableCell>
                  {data?.exif?.Make + " " + data?.exif?.Model}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MediaExifData;
