import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory, useLocation } from "react-router-dom";

import { formattedDateTime } from "../../utils/time";

const OrderTable = (props) => {
  const history = useHistory();

  const columns = [
    { field: "receiptNo", headerName: "Monet Receipt No", flex: 1 },
    { field: "razorOrderId", headerName: "RazorPay Order Id", flex: 1 },
    { field: "razorPaymentId", headerName: "RazorPay Payment Id", flex: 1 },
    {
      field: "user",
      headerName: "User Name",
      flex: 1,
      valueGetter: (params) => params.row.user?.name,
    },
    { field: "status", headerName: "TXN Status", flex: 1 },
    {
      field: "txnDate",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => {
        const date =
          params.row.statusResponse?.body?.txnDate || params.row.createdAt;
        return formattedDateTime(date);
      },
    },
    {
      field: "itemType",
      headerName: "Purpose",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              onViewClick(row);
            }}
          >
            {row?.itemType === "MediaPurchase" ? "View Media" : "View Event"}
          </Button>
        );
      },
    },
  ];

  const onViewClick = (item) => {
    console.log(item);
    if (item?.itemType === "MediaPurchase" && item?.media) {
      history.push({
        pathname: `/media/${item?.media?.id}`,
        state: { item: item?.media },
      });
    }
    if (item?.itemType === "EventEntry" && item?.event) {
      history.push({
        pathname: `/events/${item?.event?.id}`,
        state: item?.event,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={props?.data || []}
        columns={columns}
        pageSize={10}
        onRowClick={(data) => props?.onSelect(data.row)}
      ></DataGrid>
    </Box>
  );
};

export default OrderTable;
