import React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { formattedDate } from "../../utils/time";
import { TableHead } from "@material-ui/core";

const txtCodes = {
  PPI: "PAYTM WALLET",
  UPI: "BHIM UPI",
  CC: "CREDIT CARD",
  DC: "DEBIT CARD",
  NB: "NET BANKING",
};

const getTxNStatus = (status) => {
  switch (status) {
    case "IN_PROGRESS":
      return "In Progress";
    case "TXN_SUCCESS":
      return "Success";
    default:
      return "Failed";
  }
};

const OrderSummary = ({ order }) => {
  console.log(order?.payment);
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead></TableHead>
          <TableBody>
            <TableRow key="id">
              <TableCell variant="title">Receipt No</TableCell>
              <TableCell variant="value">{order?.receiptNo}</TableCell>
            </TableRow>
            <TableRow key="id">
              <TableCell variant="title">RazorPay Order Id</TableCell>
              <TableCell variant="value">{order?.razorOrderId}</TableCell>
            </TableRow>
            <TableRow key="id">
              <TableCell variant="title">RazorPay Payment Id</TableCell>
              <TableCell variant="value">{order?.razorPaymentId}</TableCell>
            </TableRow>
            <TableRow key="date">
              <TableCell variant="title">Transaction Date</TableCell>
              <TableCell variant="value">
                {formattedDate(
                  order?.statusResponse?.body?.txnDate || order?.createdAt
                )}
              </TableCell>
            </TableRow>
            <TableRow key="status">
              <TableCell variant="title">Status</TableCell>
              <TableCell variant="value">{order?.status}</TableCell>
            </TableRow>
            <TableRow key="amount">
              <TableCell variant="title">Amount</TableCell>
              <TableCell variant="value">{order?.amount}</TableCell>
            </TableRow>
            <TableRow key="method">
              <TableCell variant="title">Payment Method</TableCell>
              <TableCell variant="value">{order?.payment?.method}</TableCell>
            </TableRow>
            {order?.payment?.method === "card" && (
              <>
                <TableRow key="card-network">
                  <TableCell variant="title">Card Network</TableCell>
                  <TableCell variant="value">
                    {order?.payment?.card?.network}
                  </TableCell>
                </TableRow>
                <TableRow key="card-issuer">
                  <TableCell variant="title">Card Issuer</TableCell>
                  <TableCell variant="value">
                    {order?.payment?.card?.issuer}
                  </TableCell>
                </TableRow>
                <TableRow key="card-type">
                  <TableCell variant="title">Card Type</TableCell>
                  <TableCell variant="value">
                    {order?.payment?.card?.type}
                  </TableCell>
                </TableRow>
                <TableRow key="card-digits">
                  <TableCell variant="title">Card Last 4 digits</TableCell>
                  <TableCell variant="value">
                    {order?.payment?.card?.last4}
                  </TableCell>
                </TableRow>
              </>
            )}
            {order?.payment?.method === "wallet" && (
              <>
                <TableRow key="wallet">
                  <TableCell variant="title">Wallet</TableCell>
                  <TableCell variant="value">{order?.payment?.wallet}</TableCell>
                </TableRow>
              </>
            )}
            {order?.payment?.method === "upi" && (
              <>
                <TableRow key="upi">
                  <TableCell variant="title">UPI Address</TableCell>
                  <TableCell variant="value">{order?.payment?.vpa}</TableCell>
                </TableRow>
              </>
            )}
            <TableRow key="bank">
                  <TableCell variant="title">Bank</TableCell>
                  <TableCell variant="value">{order?.payment?.bank}</TableCell>
                </TableRow>
            <TableRow key="type">
              <TableCell variant="title">Type</TableCell>
              <TableCell variant="value">{order?.itemType}</TableCell>
            </TableRow>
            <TableRow key="type">
              <TableCell variant="title">User Name</TableCell>
              <TableCell variant="value">{order?.user?.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderSummary;
