import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import { useMutation } from "react-query";
import { APIContext } from "../../service/api-provider";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { themeSchema } from "../../utils/validate";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ThemeForm from "../../componets/ui/ThemeForm";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import defaultTheme from "../../constants/theme";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";

const CreateTheme = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = location?.state;
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();

  const { createTheme, updateTheme, deleteTheme } = useContext(APIContext);
  const createThemeMutation = useMutation((data) => createTheme(data), {
    onSuccess: (data) => {
      console.log(data);
      history.goBack();
    },
    onError: (error) => {
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const updateThemeMutation = useMutation(
    (data) => updateTheme(theme?.id, data),
    {
      onSuccess: (data) => {
        console.log(data);
        methods.reset(methods.getValues());
      },
      onError: (error) => {
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error Connecting to Server. Try again."
        );
        setShowError(true);
      },
    }
  );

  const deleteThemeMutation = useMutation(() => deleteTheme(theme?.id), {
    onSuccess: (data) => {
      console.log(data);
      history.goBack();
    },
    onError: (error) => {
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const methods = useForm({
    resolver: yupResolver(themeSchema),
    defaultValues: theme || defaultTheme,
  });

  const discardHandler = () => {
    const values = theme || defaultTheme;
    for (let key in values) {
      methods.setValue(key, values[key]);
    }
  };

  const dirtyFields = methods.formState.dirtyFields;

  const submitHandler = (data) => {
    if (theme) {
      //this is an update
      let updatedFields = {};
      for (let key in dirtyFields) {
        updatedFields[key] = methods.getValues(key);
      }

      if (!_.isEmpty(updatedFields)) {
        updateThemeMutation.mutate(updatedFields);
      }
    } else {
      createThemeMutation.mutate(data);
    }
  };

  const deleteHandler = () => {
    deleteThemeMutation.mutate();
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="pageHeader">Create New Theme</Typography>
      <FormProvider {...methods}>
        <ThemeForm />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button variant="contained" onClick={discardHandler}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={methods.handleSubmit(submitHandler)}
          >
            {theme ? "Update" : "Create"}
          </Button>
          {theme && (
            <Button variant="contained" onClick={deleteHandler}>
              Delete
            </Button>
          )}
        </Box>
      </FormProvider>

      <InfoAlert
        open={showError}
        title="Error!"
        body={error}
        onClose={() => setShowError(false)}
      />

      <ProgressIndicator
        open={
          createThemeMutation.isLoading ||
          updateThemeMutation.isLoading ||
          deleteThemeMutation.isLoading
        }
      />
    </Box>
  );
};

export default CreateTheme;
