
import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { APIProvider } from "./service/api-provider";
import { QueryClient, QueryClientProvider } from "react-query";

import BaseRoute from "./routes/BaseRoute";

import "./App.css";
import theme from "./utils/theme";

function App() {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <APIProvider>
            <BaseRoute />
        </APIProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
