import React, { useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

function MediaActions({ data, onSave, onAccept, onReject }) {
  const [marketPlace, setMarketPlace] = useState(data.marketPlace || false);
  const [monetPrice, setMonetPrice] = useState(data.monetPrice);

  const saveHandler = () => {
    onSave({
      marketPlace,
      monetPrice,
    });
  };

  return (
    <Box>
      <Typography variant="pageHeader">Actions</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow key="marketplace">
              <TableCell variant="title">Market Place</TableCell>
              <TableCell>
                <Checkbox
                  checked={marketPlace}
                  onChange={(event) => setMarketPlace(event.target.checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow key="price">
              <TableCell variant="title">Monet Price</TableCell>
              <TableCell>
                <TextField
                  sx={{ width: 150 }}
                  value={monetPrice}
                  onChange={(event) => setMonetPrice(event.target.value)}
                />
              </TableCell>
            </TableRow>
            {data.marketStatus === "userRejected" && (
              <>
                <TableRow key="userPrice" sx={{ "& td": { borderBottom: 0 } }}>
                  <TableCell variant="title">User Price</TableCell>
                  <TableCell>{data.userPrice}</TableCell>
                </TableRow>
                <TableRow
                  key="buttons"
                  sx={{ "& td": { borderTop: 0, padding: 0 } }}
                >
                  <TableCell sx={{ display: "flex", flexDirection: "row" }}>
                    <Button variant="contained" onClick={onAccept}>
                      Accept
                    </Button>
                    <Button variant="contained" onClick={onReject}>
                      Reject
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </>
            )}
            {data.marketStatus === "adminRejected" && (
              <TableRow key="userPrice" sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell variant="title">User Price</TableCell>
                <TableCell>{data.userPrice}</TableCell>
              </TableRow>
            )}
            <TableRow key="marketstatus">
              <TableCell variant="title">MarketPlace Status</TableCell>
              <TableCell>{data?.marketPlace ? data.marketStatus : "Not in MarketPlace"} </TableCell>
            </TableRow>
            {data.marketStatus === "accepted" && (
              <TableRow key="marketPrice">
                <TableCell variant="title">Market Price</TableCell>
                <TableCell>{data.marketPrice}</TableCell>
              </TableRow>
            )}

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button variant="contained" onClick={saveHandler}>
                Save
              </Button>
            </Box>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MediaActions;
