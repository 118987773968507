import React, { useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";

import { formattedDate } from "../../utils/time";

const MediaDetails = ({ data, onApprove, onReject, marketPlace }) => {
  const [rating, setRating] = useState(data.rating || 0);

  const handleRating = (value) => {
    setRating(value);
  };

  const approveHandler = () => {
    onApprove(rating);
  };

  const rejectHandler = () => {
    onReject(rating);
  };

  const pending = data?.status === "pending";

  return (
    <Box>
      <Typography variant="pageHeader">Details</Typography>
      <TableContainer component={Paper} sx={{ mt: 1 }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow key="name">
              <TableCell variant="title">Event Name</TableCell>
              <TableCell>{data?.event?.title}</TableCell>
            </TableRow>
            <TableRow key="desc">
              <TableCell variant="title">Description</TableCell>
              <TableCell>{data?.event?.description || ""}</TableCell>
            </TableRow>
            <TableRow key="tags">
              <TableCell variant="title">Tags</TableCell>
              <TableCell>{data?.tags}</TableCell>
            </TableRow>
            {data?.dimension && (
              <TableRow key="dimension">
                <TableCell variant="title">Dimensions</TableCell>
                <TableCell>{data?.dimension}</TableCell>
              </TableRow>
            )}
            {data?.handmade !== undefined && (
              <TableRow key="tags">
                <TableCell variant="title">Make</TableCell>
                <TableCell>
                  {data?.handmade ? "Handmade" : "Digitally Derived"}
                </TableCell>
              </TableRow>
            )}
            <TableRow key="creator">
              <TableCell variant="title">User Name</TableCell>
              <TableCell>{data?.creator?.name}</TableCell>
            </TableRow>
            <TableRow key="creatorEmail">
              <TableCell variant="title">User Email</TableCell>
              <TableCell>{data?.creator?.email}</TableCell>
            </TableRow>
            {marketPlace ? (
              <TableRow key="date">
                <TableCell variant="title">Submission Date</TableCell>
                <TableCell>{formattedDate(data?.submissionDate)}</TableCell>
              </TableRow>
            ) : null}
            {data?.status && (
              <TableRow key="status">
                <TableCell variant="title">Status</TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {data.status}
                </TableCell>
              </TableRow>
            )}
            {data?.statusReason && (
              <TableRow key="reason">
                <TableCell component="th" sx={{ fontWeight: "bold" }}>
                  Reason for reject
                </TableCell>
                <TableCell align="right">{data.statusReason}</TableCell>
              </TableRow>
            )}
            <TableRow key="rating">
              <TableCell variant="title">Rating</TableCell>
              <TableCell>
                <Rating
                  name="rating"
                  value={rating}
                  readOnly={!pending}
                  onChange={(event, newValue) => {
                    handleRating(newValue);
                  }}
                />
              </TableCell>
            </TableRow>
            {pending && (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Button variant="contained" onClick={rejectHandler}>
                  Reject
                </Button>
                <Button variant="contained" onClick={approveHandler}>
                  Approve
                </Button>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MediaDetails;
