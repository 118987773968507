import React, { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { APIContext } from "../../service/api-provider";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ConfirmAlert from "../../componets/ui/dialogs/ConfirmAlert";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";
import EventCard from "../../componets/events/EventCard";
import DescriptionAlerts from "../../componets/ui/dialogs/DescriptionAlerts";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";
import PaginationUI from "../../componets/ui/PaginationUI";
import EventTable from "../../componets/events/EventTable";

function Events() {
  const [pageNo, setPageNo] = useState(1);
  const [showAlert, setAlert] = useState(false);
  const [error, setError] = useState();
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [eventSelected, selectEvent] = useState();
  const [gridView, setGridView] = useState(true);

  const match = useRouteMatch();
  const history = useHistory();

  const queryClient = useQueryClient();
  const { getEvents, deleteEvent } = useContext(APIContext);

  const { data, isLoading } = useQuery(
    ["events", pageNo],
    () => getEvents(pageNo),
    {
      keepPreviousData: true,
    }
  );

  const deleteEventMutation = useMutation((id) => deleteEvent(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["events"]);
    },
    onError: (error) => {
      console.log(error);
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error connecting to server. Try again"
      );
      setErrorAlert(true);
    },
  });

  const createEventHandler = () => {
    history.push({
      pathname: `${match.path}/create`,
    });
  };

  const pageChangeHandler = (event, value) => {
    setPageNo(value);
  };

  const editEventHandler = (event) => {
    history.push({
      pathname: `${match.path}/${event.id}/edit`,
      state: event,
    });
  };

  const deletePressHandler = (event) => {
    selectEvent(event);
    setAlert(true);
  };

  const deleteEventHandler = () => {
    setAlert(false);
    deleteEventMutation.mutate(eventSelected?.id);
  };

  const eventSelectHandler = (event) => {
    history.push({
      pathname: `${match.path}/${event.id}`,
      state: event,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={createEventHandler}
          sx={{ marginRight: 2 }}
        >
          Create Event
        </Button>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ px: 24 }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => setGridView(true)}
        >
          Grid
        </Button>
        <Button variant="outlined" onClick={() => setGridView(false)}>
          Table{" "}
        </Button>
      </Box>

      {!data?.data?.results && !isLoading && <DescriptionAlerts />}

      {gridView ? (
        <Box>
          {data?.data?.results && data?.data?.totalPages > 1 && (
            <PaginationUI
              page={pageNo}
              totalPages={data?.data?.totalPages}
              handleChange={pageChangeHandler}
            />
          )}

          <Grid container spacing={0}>
            {data?.data?.results?.map((item) => (
              <EventCard
                key={item.id}
                event={item}
                onPress={() => eventSelectHandler(item)}
                onEditPress={() => editEventHandler(item)}
                onDeletePress={() => deletePressHandler(item)}
              />
            ))}
          </Grid>
        </Box>
      ) : (
        <Box sx={{ px: 2, my: 3 }}>
          <EventTable onSelect={eventSelectHandler} />
        </Box>
      )}

      <ConfirmAlert
        open={showAlert}
        onClose={() => setAlert(false)}
        onAgree={deleteEventHandler}
        title="Delete Event?"
        body="Are you sure, you want to delete the Event?"
        confirmButton="Delete"
      />

      <InfoAlert
        open={showErrorAlert}
        onClose={() => setErrorAlert(false)}
        title="Error!"
        body={error}
      />

      <ProgressIndicator open={isLoading || deleteEventMutation?.isLoading} />
    </Box>
  );
}

export default Events;
