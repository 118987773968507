import React, { useCallback, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useMutation, useQueryClient, useQuery } from "react-query";
import { APIContext } from "../../service/api-provider";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { TableHead } from "@material-ui/core";

import PaginationUI from "../../componets/ui/PaginationUI";
import MediaList from "../../componets/events/MediaList";
import UserDetailsForm from "../../componets/UserDetailsForm";
import InfoAlert from "../../componets/ui/dialogs/InfoAlert";
import ProgressIndicator from "../../componets/ui/ProgressIndicator";

function UserDetails() {
  const history = useHistory();
  const location = useLocation();
  const user = location?.state?.user;
  const [pageNo, setPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();

  const queryClient = useQueryClient();

  const [blockStatus, setBlockStatus] = useState(user?.isBlocked);

  const { updateUser, userAction, getUserSubmissions } = useContext(APIContext);

  const { data: submissions } = useQuery(
    ["media", { user: user?.id, page: pageNo }],
    () => getUserSubmissions(user?.id, [pageNo]),
    { keepPreviousData: true, select: (data) => data?.data }
  );

  const updateUserMutation = useMutation(
    ({ id, data }) => updateUser(id, data),
    {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error Connecting to Server. Try again."
        );
        setShowError(true);
      },
    }
  );

  const userBlockMutation = useMutation((data) => userAction(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users", 1]);
    },
    onError: (error) => {
      console.log(error);
      setError(
        error?.response?.data?.message ||
          error?.message ||
          "Error Connecting to Server. Try again."
      );
      setShowError(true);
    },
  });

  const updateHandler = (data) => {
    updateUserMutation.mutate({
      id: user.id,
      data: data,
    });
  };

  const onBlockActionHandler = useCallback(() => {
    setBlockStatus((prev) => !prev);

    userBlockMutation.mutate({
      id: user?.id,
      action: blockStatus ? "remove" : "add",
    });
  }, [userBlockMutation, blockStatus]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const mediaSelectHandler = (item) => {
    console.log(item)
    history.push({
      pathname: `/media/${item.id}`,
      state: { item: item, marketplace: item?.marketPlace},
    });
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="pageHeader">User Details</Typography>

      <Box sx={{ mt: 2, mb: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow key="name">
                <TableCell variant="title">Likes Recieved</TableCell>
                <TableCell variant="title">Events Participated</TableCell>
                <TableCell variant="title">Likes Given</TableCell>
                <TableCell variant="title">Likes Recieved</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="startDate">
                <TableCell variant="value">
                  {user?.stats?.likesRecieved}
                </TableCell>
                <TableCell variant="value">
                  {user?.stats?.eventsParticipated}
                </TableCell>
                <TableCell variant="value">{user?.stats?.likesGiven}</TableCell>
                <TableCell variant="value">
                  {user?.stats?.likesRecieved}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" onClick={onBlockActionHandler}>
          {blockStatus ? "Unblock" : "Block"}
        </Button>
      </Box>

      <Box sx={{my: 4}}>
      <Typography sx={{mb: 2, fontSize: 18, color: 'black'}}>Media Submissions</Typography>
        {submissions?.results && submissions?.totalPages > 1 && (
          <PaginationUI
            page={pageNo}
            totalPages={submissions?.totalPages}
            handleChange={handlePageChange}
          />
        )}

        <MediaList media={submissions?.results} onSelect={mediaSelectHandler} />
      </Box>

      {user && <UserDetailsForm user={user} onSubmit={updateHandler} />}
      <InfoAlert
        open={showError}
        title="Error!"
        body={error}
        onClose={() => setShowError(false)}
      />
      <ProgressIndicator open={updateUserMutation.isLoading} />
    </Box>
  );
}

export default UserDetails;
