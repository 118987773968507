import React from "react";

import Box from "@mui/material/Box";

const ColorIndicator = (props) => {
  return (
    <Box
      sx={{
        width: 50,
        height: 50,
        backgroundColor: props.color,
        borderRadius: 4,
        ml: 2,
      }}
    ></Box>
  );
};

export default ColorIndicator;
