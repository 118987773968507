import { Box, Button } from "@mui/material";
import { Table } from "reactstrap";

import "../../css/Table.css";

const ReportTable = (props) => {
  let i = 1;
  return (
    <div style={{ width: "98%", marginLeft: 20, marginTop: 20 }}>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Sl.no</th>
            <th>Report By</th>
            <th>Report Type</th>
            <th>Media</th>
            <th>Reason</th>
            <th>Action</th>
          </tr>
        </thead>

        {props.reportList
          ? props.reportList.map((item) => {
              return (
                <tbody onClick={(e) => e.stopPropagation()}>
                  <tr>
                    <td>{i++}</td>
                    <td>{item?.user?.email}</td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.type === "media" ? (
                        <Box display="flex" alignItems="center">
                          <img src={item?.media?.url} style={{ height: 100 }} />
                        </Box>
                      ) : (
                        item?.comment?.comment || "-"
                      )}
                    </td>
                    <td>{item?.reason} </td>
                    <td>
                      <Box display="flex" alignItems="center">
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ mr: 3 }}
                          onClick={() => props?.onIgnoreReport(item?.id)}
                        >
                          Ignore
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => props?.onDeleteContent(item)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              );
            })
          : null}
      </Table>
    </div>
  );
};

export default ReportTable;
